<template>
  <div id="leads">
    <div class="wrapper_main">
      <div class="wrapper_content">
        <div class="title">{{ $t('menu.leads') }}</div>

        <div class="item_header flex flex-between">
          <AccountNumber @accountCallback="queryLeads">
            <p>{{ $t('ibAccounts.ibAccount') }}</p></AccountNumber
          >

          <ul class="header_btn_container">
            <li :class="{ 'is-plain': selected !== 1 }" @click="resetNormalPage" data-testid="resetNormalPage">
              {{ $t('lead.demoAccounts') }}
            </li>
            <li :class="{ 'is-plain': selected !== 2 }" @click="resetDemoPage" data-testid="resetDemoPage">
              {{ $t('menu.leads') }}
            </li>
          </ul>
        </div>
        <ul class="table clearfix">
          <li :class="{ active: selected === 1 }">
            <div class="mb-3">
              <el-table :data="displayDemoLeads" style="width: 100%">
                <el-table-column :label="$t('common.keys.DATE')" min-width="100">
                  <template slot-scope="scope">
                    <span>{{ scope.row.create_time | date('DD/MM/YYYY') }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="real_name" :label="$t('common.keys.NAME')" min-width="100"></el-table-column>
                <el-table-column prop="email" :label="$t('common.keys.EMAIL')" min-width="100"></el-table-column>
                <el-table-column :label="$t('common.keys.ACCTYPE')" min-width="100">
                  <template slot-scope="scope">
                    {{ $platform.accountTypeMaps(regulator)[scope.row.accountMT4Type] }}
                  </template>
                </el-table-column>
                <el-table-column :label="$t('common.keys.PLATFORM')" min-width="100">
                  <template slot-scope="scope">
                    <span>{{ scope.row.platform | formatPlatform() }}</span>
                  </template>
                </el-table-column>
                <el-table-column :label="$t('common.keys.BASECURRENCY')" min-width="120">
                  <template slot-scope="scope">
                    <span>{{ scope.row.apply_currency | formatUppercase() }}</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <Pagination
              ref="demoPagination"
              v-bind:table-data="demoLeads"
              v-bind:display-data.sync="displayDemoLeads"
            ></Pagination>
          </li>
          <li :class="{ active: selected === 2 }">
            <div class="mb-3">
              <el-table :data="displayNormalLeads" style="width: 100%">
                <el-table-column :label="$t('common.keys.DATE')">
                  <template slot-scope="scope">
                    <span>{{ scope.row.create_time | date('DD/MM/YYYY') }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="real_name" :label="$t('common.keys.NAME')"></el-table-column>
                <el-table-column prop="email" :label="$t('common.keys.EMAIL')"></el-table-column>
              </el-table>
            </div>
            <Pagination
              ref="normalPagination"
              v-bind:table-data="normalLeads"
              v-bind:display-data.sync="displayNormalLeads"
            ></Pagination>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination';
import AccountNumber from '@/components/form/AccountNumber';
import { apiLeads } from '@/resource';

export default {
  components: { Pagination, AccountNumber },
  data() {
    return {
      userID: this.$store.state.common.CUID,
      leads: [],
      demoLeads: [],
      displayDemoLeads: [],
      normalLeads: [],
      displayNormalLeads: [],
      selected: 1
    };
  },
  mounted() {
    // 表格切换效果
    $('.leads_item li').click(function () {
      // 获取当前索引
      var index = $(this).index();
      $(this).addClass('active').siblings('li').removeClass('active');
      $('.table_box_item>li:eq(' + index + ')')
        .addClass('active')
        .siblings('li')
        .removeClass('active');
    });
  },
  methods: {
    queryLeads() {
      apiLeads({ userId: this.userID }).then(resp => {
        if (resp.status == 200) {
          this.leads = resp.data;
          this.updateLeads();
        }
      });
    },
    updateLeads() {
      this.demoLeads = [];
      this.normalLeads = [];
      this.leads.forEach(element => {
        if (element.affid != this.accountID) {
          return;
        }
        if (element.website_user_type == '1' && element.accountMT4Type) {
          this.demoLeads.push(element);
          return;
        }
        if (element.website_user_type == '8') {
          this.normalLeads.push(element);
        }
      });
      this.resetDemoPage();
      this.resetNormalPage();
    },
    resetDemoPage() {
      this.selected = 2;
      this.$refs.demoPagination.resetPage();
    },
    resetNormalPage() {
      this.selected = 1;
      this.$refs.normalPagination.resetPage();
    }
  },
  watch: {
    accountID() {
      this.updateLeads();
    }
  },
  computed: {
    accountID() {
      return this.$store.state.behavior.AID;
    },
    lang() {
      return this.$store.state.common.lang;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/leads.scss';
</style>
